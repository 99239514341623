import { JwtPayload } from '@aws-amplify/core/internals/utils';
import { setUser } from '@sentry/react';

export const setUserContext = (tokenPayload: JwtPayload | null = null) => {
    if (!tokenPayload) {
        setUser(null);
        return;
    }
    const { sub, email } = tokenPayload;
    const userContext = {
        id: sub,
        email: typeof email === 'string' ? email : undefined
    }
    setUser(userContext);
};
