import { Card, Button } from "@aws-amplify/ui-react";

const FallBackComponent = ({ error, resetError }: { error: Error, resetError: () => void }) => {
    return (
        <Card
        id='OutputForm'
        textAlign='left'
        className="flex flex-col justify-center items-center"
        paddingInline='32px'
        paddingBlockStart='24px'
        paddingBlockEnd='50px'
    >
            <p className="pb-2">
                Si è verificato un errore: <code>{ error.message }</code>
            </p>
            <Button 
                className="border-0 pastone-icon-btn btn--active"
                padding='2'
                onClick={resetError}
            >
                Resetta il form
            </Button>
        </Card>
    );
};

export default FallBackComponent;