import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';

const setupSentry = () => {
    Sentry.init({
       dsn: "https://3b422b2d790d54d1ad1664040c8ac6e4@o234395.ingest.us.sentry.io/4506852711596032",
       environment: APP_ENV,
       integrations: [
           Sentry.browserTracingIntegration(),
           Sentry.replayIntegration({
             maskAllText: false,
             blockAllMedia: false,
           }),
         ],
       // Performance Monitoring
       tracesSampleRate: parseFloat(APP_SENTRY_SAMPLE_RATE as string),
       // Session Replay
       replaysSessionSampleRate: parseFloat(APP_SENTRY_SESSION_RATE as string),
       replaysOnErrorSampleRate: parseFloat(APP_SENTRY_SESSION_ERROR_RATE as string),
    });
   };

export const initConfig = () => {
    setupSentry();
    const redirect = [D360_LOGIN_REDIRECT_URI];
    const awsConfig = {
        Auth: {
            Cognito: {
                region: APP_REGION,
                userPoolId: APP_USER_POOL_ID,
                userPoolClientId: APP_USER_POOL_CLIENT_ID,
                identityPoolId: APP_IDENTITY_POOL_ID,
                loginWith: {
                    oauth: {
                        domain: D360_LOGIN_DOMAIN,
                        scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                        redirectSignIn: redirect,
                        redirectSignOut: redirect,
                        responseType: 'code' as 'code' | 'token',
                    }
                }
            },
        },
        Storage: {
            S3: {
                bucket: APP_BUCKET,
                region: APP_REGION
            }
        }
    };

    Amplify.configure(awsConfig);
};