import { Button, Flex } from "@aws-amplify/ui-react";

const Disclaimer = ({
    disclaimerRef,
    onAccept,
    onRefuse
}: {
    disclaimerRef: React.RefObject<HTMLDialogElement>,
    onAccept: () => void,
    onRefuse: ((data?: unknown) => void) | undefined
}) => {
    return (
        <dialog
            ref={disclaimerRef}
            id="disclaimer"
            className="modal"
        >
            <div className="modal-box max-w-[600px] !rounded-md">
                <p
                    className="px-8 py-5 text-base font-normal"
                >
                    <p className="mb-6">
                        Web 2 Whitepaper è uno strumento aziendale di GenerativeAI basato sul modello di linguaggio avanzato Claude 3 di Anthropic. Questo tool elabora contenuti forniti dall'utente tramite URL o documenti caricati (input) e genera elaborati/bozze di articoli o whitepaper (output) grazie ad algoritmi di apprendimento automatico.
                    </p>
                    <p className="mb-6">
                        Gli utenti devono:
                    </p>
                    <ol className="mb-6 list-outside px-4 list-decimal">
                        <li>
                            inserire come input soltanto contenuti che siano liberi da diritto d’autore o di cui si possieda idonea licenza; in alternativa, usare contenuti che rientrano nel dominio pubblico o rilasciati sotto “licenze creative commons”, così anche da evitare di divulgare informazioni, ottenute attraverso le elaborazioni effettuate dallo strumento, coperte dal diritto d’autore;
                        </li>
                        <li>
                            verificare con attenzione l’output, prima di qualsiasi suo utilizzo, anche interno;
                        </li>
                        <li>
                            utilizzare lo strumento in modo sicuro, soltanto per finalità lavorative e nel rispetto della policy aziendale sull’utilizzo degli strumenti informatici, nonché in conformità a quanto disposto da ogni normativa vigente.
                        </li>
                    </ol>
                    <p className="mb-6">
                        Agli utenti è fatto divieto di inserire:
                    </p>
                    <ol className="mb-6 list-outside px-4 list-decimal">
                        <li>
                            contenuti di tipo offensivo, ingannevole, osceno, fraudolento, diffamatorio, calunnioso, discriminatorio, falso ed in qualsiasi modo non conforme alla normativa vigente o ai principi dell’ordine pubblico e del buon costume;
                        </li>
                        <li>
                            contenuti coperti da vincoli di riservatezza;
                        </li>
                        <li>
                            informazioni riservate relative al Gruppo Digital360 o a società o collaboratori comunque collegati allo stesso;
                        </li>
                        <li>
                            dati personali considerati “sensibili” (o categorie particolari di dati di cui all’art. 9 del GDPR), cioè dati relativi alla salute, dati genetici e biometrici, dati che rivelino l'origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, l'appartenenza sindacale o l’orientamento sessuale.
                        </li>
                    </ol>
                    In ogni caso gli utenti devono rispettare quanto indicato nella policy aziendale in materia, anche in relazione all’uso appropriato dello strumento, al fine di mitigare adeguatamente i rischi intrinsechi dello strumento.
                </p>
                <div className="modal-action m-0 sticky bg-white bottom-0 border-gray-300 border-t">
                    <form
                        method="dialog"
                        className="grow"
                    >
                        <Flex
                            className="justify-between px-4 py-2"
                        >
                            <Button
                                className="btn-sm rounded-none h-9 px-2 font-normal text-sm text-[#616161] border-0 focus:shadow-none"
                                onClick={onRefuse}
                            >
                                NON ACCETTO
                            </Button>
                            <Button
                                className="btn-sm rounded-none h-9 px-2 font-normal text-sm text-primary-blue border-0 focus:shadow-none"
                                onClick={onAccept}
                            >
                                ACCETTO
                            </Button>
                        </Flex>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default Disclaimer;