import {
    SignInInput,
    resetPassword,
    signIn
} from 'aws-amplify/auth';
import { CustomWindow } from "types";

const resetPasswordWrapper = async (username: string) => {
    return resetPassword({ username }).catch(async (err) => {
        if ('NotAuthorizedException' === err.name && "User password cannot be reset in the current state." === err.message) {
            await fetch(
                APP_ENDPOINT_API + 'resendPassword',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: username
                    })
                }
            );
            (window as CustomWindow).recoverPasswordNotice = true;
            const toSignInFunc = (window as CustomWindow).toSignIn;
            if (toSignInFunc) {
                toSignInFunc();
            }
            return err;
        }
        throw (err);
    });
}

const services = {
    async handleForgotPassword(formData: { username: string }) {
        const { username } = formData;
        return resetPasswordWrapper(username);
    },
    async handleSignIn(input: SignInInput) {
        (window as CustomWindow).prevState = '';
        return signIn(input);
    }
}

export default services;

