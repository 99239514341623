import * as yup from 'yup';

export const config = {
    MAX_SOURCES: 10
}

const schema = yup.object().shape({
    contentType: yup.string().oneOf(['ARTICLE', 'WHITEPAPER']).required(),
    headingsNumber: yup.number().min(1).max(20).required(),
    headingsType: yup.string().oneOf([
        'h2', 'h3', 'h4'
    ]).required(),
    links: yup.array().of(yup.object({
        type: yup.string().oneOf(['LINK']).required(),
        value: yup.string().required(),
    })),
    files: yup.array().of(yup.string().required())
}).test(
    'links or files',
    'links or files is required',
    (value, { createError }) =>
        (value &&
        (value.links?.length !== 0 || value.files?.length !== 0) &&
        ((value.links?.length ?? 0) + (value.files?.length ?? 0) <= config.MAX_SOURCES)) ??
        createError({
            path: 'invalidForm',
            message: 'Inserire almeno un file o un link.'
        })
)

export default schema;