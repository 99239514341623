import { useCallback, useState } from 'react';
import {
    Button,
    TextField,
    Flex,
} from '@aws-amplify/ui-react';
import {
    DndContext,
    closestCenter,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove
} from '@dnd-kit/sortable';
import {
    restrictToVerticalAxis,
    restrictToParentElement
  } from '@dnd-kit/modifiers';
import { HandleFieldsFuncs } from 'types';
import SortableItem from './SortableItem';

import addIcon from '/assets/add_black_24dp.svg';
import removeIcon from '/assets/clear_black_24dp.svg';

function FieldsArrayComponent({
    fields,
    fieldsFuncs,
    editable
}: {
    fields: string[],
    fieldsFuncs: HandleFieldsFuncs,
    editable: boolean
}) {
    const [inputText, setInputText] = useState<string>('');

    const addLink = useCallback(
        () => {
            const trimmedInput = inputText.trim();
            if (trimmedInput && !fields.includes(trimmedInput)) {
                fieldsFuncs.addField(trimmedInput);
                setInputText('');
                return;
            }
        },
        [fields, fieldsFuncs, inputText]
    );

    const handleFieldChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
            if (editable) {
                const newFields = [...fields];
                newFields[index] = event.target.value;
                fieldsFuncs.editFields(newFields);
            }
        },
        [editable, fields, fieldsFuncs]
    );

    const handleDeleteLink = useCallback(
        (index: number) => {
            fieldsFuncs.deleteField(index);
        },
        [fieldsFuncs]
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setInputText(event.target.value);
        },
        []
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (over && active.id !== over.id) {
                const newFields = arrayMove(
                    fields,
                    active.data.current?.sortable.index,
                    over.data.current?.sortable.index,
                );
                fieldsFuncs.editFields(newFields);
            }
        },
        [fields, fieldsFuncs]
    );

    return (
        <Flex
            className='fields-array'
            direction='column'
            gap='16px'
        >            
            <Flex
                gap='0'
                alignItems='center'
                className='add-new-field'
            >
                <TextField
                    className='fieldsarray-textarea'
                    label='new-field'
                    labelHidden
                    value={inputText}
                    onChange={handleChange}
                    textAlign="left"
                    grow='1'
                >                    
                </TextField>
                <Button
                    onClick={addLink}
                    alignSelf='flex-end'
                    backgroundColor='#008AC9'
                    backgroundImage={`url("${addIcon}")`}
                ></Button>
            </Flex>
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[
                    restrictToVerticalAxis,
                    restrictToParentElement
                ]}
            >
                <SortableContext
                    items={fields}
                    strategy={verticalListSortingStrategy}
                    disabled={!editable}
                >
                    {fields.map((text, index) => (
                        <SortableItem
                            key={index}
                            id={text}
                            editable={editable}
                        >
                            <Flex
                                gap='0'
                                alignItems='center'
                                className='grow'
                            >
                                <TextField
                                    className='fieldsarray-textarea'  
                                    label=''
                                    labelHidden
                                    value={text}
                                    grow='1'
                                    onChange={(e) => handleFieldChange(e, index)}
                                    readOnly={!editable}
                                >
                                </TextField>
                                <Button
                                    border='1px solid #E0E0E0'  
                                    onClick={() => handleDeleteLink(index)}
                                    backgroundColor='#ffffff'
                                    backgroundImage={`url("${removeIcon}")`}
                                ></Button>
                            </Flex>
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        </Flex>
    );
}

export default FieldsArrayComponent;

