import { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, Flex, Button } from '@aws-amplify/ui-react';
import SubmissionForm from './SubmissionForm';
import OutputForm from './OutputForm';
import { AuthProps, HandleFieldsFuncs, SubmissionData, SubmissionHandler, ErrorProp } from 'types';
import usePromptWebSocket from 'hooks/webSocketHook';
import HeadingsModal from 'pages/MainPage/Modals/HeadingsModal';
import ErrorModal from './Modals/ErrorModal';
import { setUserContext } from 'helpers/Sentry';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { decodeJWT, fetchAuthSession } from 'aws-amplify/auth';
import FallBackComponent from 'components/FallbackComponent';
import logoutIcon from '/assets/logout_black_24dp.svg';
import ResetModal from './Modals/ResetModal';
import Disclaimer from './Modals/Disclaimer';

const MainPage = ({ signOut }: AuthProps) => {
    const [responseHeadings, setResponseHeadings] = useState<string[]>([]);
    const [requestHeadings, setRequestHeadings] = useState<string[]>([]);
    const [waitingForHeadings, setWaiting] = useState<boolean>(false);
    const [generating, setGenerating] = useState<boolean>(false);
    const headingsModalRef = useRef<HTMLDialogElement>(null);
    const errorModalRef = useRef<HTMLDialogElement>(null);
    const resetModalRef = useRef<HTMLDialogElement>(null);
    const disclaimerRef = useRef<HTMLDialogElement>(null);
    const [contentType, setContentType] = useState<string>('');
    const [sourceLinks, setLinks] = useState<string[]>([]);
    const [lastError, setLastError] = useState<ErrorProp|null>(null);
    const [session, setSession] = useState<string>((Math.random() + 1).toString(36).substring(7));
    const [outputSession, setOutputSession] = useState<string>((Math.random() + 1).toString(36).substring(7));
    const [connectionId, setConnectionId] = useState<string>('');
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>();

    // Effect per impostare il context user su Sentry
    useEffect(() => {
        async function getCurrentSession() {
            try {
                const token = `${(await fetchAuthSession()).tokens?.idToken}`;
                const { payload } = decodeJWT(token)
                if (payload) {
                    setUserContext(payload);
                }
            } catch(error) {
                console.error(error);
            }
        }
        getCurrentSession();
        return () => {
            setUserContext()
        }
    }, []);

    const {
        lastMessage,
        sendJsonMessage,
        setShouldConnect,
        user
    } = usePromptWebSocket();

    useEffect(() => {
        if (!user) {
            return;
        }
        const consent = localStorage.getItem(`${user}_disclaimer`);
        if (consent) {
            setShowDisclaimer(false);
            return;
        }
        setShowDisclaimer(true);
    }, [user, setShowDisclaimer]);

    const setAccepted = () => {
        localStorage.setItem(`${user}_disclaimer`, 'true');
        setShowDisclaimer(false);
    }

    /**
     * Effect che parte durante la submission dei form
     * e che gestisce gli headings.
     */
    useEffect(() => {
        if (lastMessage && typeof (lastMessage.data) !== 'undefined') {
            try {
                const data = JSON.parse(lastMessage.data);
                if (data.connectionId) {
                    setConnectionId(data.connectionId);
                    return;
                }
                if (data.stop_reason) {
                    setGenerating(false);
                    return;
                }
                if (data.type) {
                    return;
                }
                if (data.errorCode) {
                    setLastError({
                        errorCode: data.errorCode,
                        resources: data.failedResources ?? []
                    });
                    setWaiting(false);
                    setGenerating(false);
                    Sentry.addBreadcrumb({
                        category: 'app',
                        message: 'Ricevuto errore gestito da backend',
                        level: 'error'
                    });
                    Sentry.captureException(new Error(data.error), (scope) => {
                        scope.setTag('component', 'MainPage');
                        scope.setContext('app', {
                            errorMessage: data.error,
                            errorCode: data?.errorCode ?? 'NO_ERR_CODE_BACKEND',
                            failedResources: data?.failedResources ?? [],
                            awsRequestId: data.awsRequestId
                        });
                        return scope;
                    });
                    return;
                }
                const headings = data.headings;
                setResponseHeadings(headings);
                setRequestHeadings(headings);
                setWaiting(false);
            } catch(err) {
                console.error('Non è stato possibile estrarre gli headings.', err);
                Sentry.addBreadcrumb({ category: 'app', message: 'Errore lato client o di rete', level: 'error'});
                Sentry.captureException(err, (scope) => {
                    scope.setTag('component', 'MainPage');
                    return scope;
                })
            }
        }
    }, [lastMessage, setResponseHeadings, setLastError]);

    useEffect(() => {
        if (0 !== responseHeadings.length) {
            headingsModalRef.current?.showModal();
        }
    }, [responseHeadings]);

    useEffect(() => {
        if (null !== lastError) {
            errorModalRef.current?.showModal();
        }
    }, [lastError]);

    useEffect(() => {
        if (showDisclaimer) {
            disclaimerRef.current?.showModal();
            return;
        }
        disclaimerRef.current?.close();
    }, [showDisclaimer]);

    const openResetModal = () => {
        resetModalRef.current?.showModal();
    }

    const onSubmit: SubmissionHandler = useCallback(
        (data: SubmissionData) => {
            setOutputSession((Math.random() + 1).toString(36).substring(7));
            setWaiting(true);
            setGenerating(true);
            setShouldConnect(true);
            setContentType(data.contentType);
            const filesNoDupes = Array.from(new Set(data.files));
            const filesArr = filesNoDupes.map(item => ({
                type: 'URL',
                value: item.substring(1),
            }));
            const links = [...(data.links || [])];
            setLinks(links.map(link => link.value));
            const payload = {
                "n_headings": data.headingsNumber,
                "tag_headings": data.headingsType,
                "type": data.contentType,
                "links": [...links, ...filesArr]
            };
            Sentry.addBreadcrumb({
                category: 'app',
                message: 'Invio form',
                level: 'info',
                data: payload,
            });
            sendJsonMessage(payload);
        },
        [sendJsonMessage, setShouldConnect]
    );

    const submitHeaders = useCallback(
        () => {
            const message = {
                type: contentType,
                headings: requestHeadings
            };
            sendJsonMessage(message);
            headingsModalRef.current?.close();
        },
        [contentType, requestHeadings, sendJsonMessage]
    )

    const createFieldsFuncs = useCallback(
        (
            setState: React.Dispatch<React.SetStateAction<string[]>>
        ): HandleFieldsFuncs => {
            const addField = (inputText: string) => {
                setState(prevFields => [...prevFields, inputText]);
            };
            const editFields = (edited: string[]) => {
                setState(edited);
            };
            const deleteField = (index: number) => {
                setState(prevFields => prevFields.toSpliced(index, 1));
            };
            return {
                addField,
                editFields,
                deleteField,
            };
        },
        []
    );

    const triggerClean = useCallback(
        () => {
            setOutputSession((Math.random() + 1).toString(36).substring(7));
            setSession((Math.random() + 1).toString(36).substring(7));
            resetModalRef.current?.close();
        },
        []
    );

    return (
        <main
            className='w-full h-full'
        >
            <HeadingsModal
                modalRef={headingsModalRef}
                headings={requestHeadings}
                headingsFuncs={createFieldsFuncs(setRequestHeadings)}
                onSubmit={submitHeaders}
            ></HeadingsModal>
            <ErrorModal
                links={sourceLinks}
                modalRef={errorModalRef}
                error={lastError}
                onClose={useCallback(
                    () => {
                        setLastError(null);
                        errorModalRef.current?.close();
                    },
                    []
                )}
            >
            </ErrorModal>
            <ResetModal
                modalRef={resetModalRef}
                triggerClean={triggerClean}
            />
            <Disclaimer
                disclaimerRef={disclaimerRef}
                onAccept={setAccepted}
                onRefuse={signOut}
            />
            <Flex alignItems="center" justifyContent="space-between" className="h-[3.75rem] py-4 px-8 border-b-4 bg-white navbar text-black" >
                <p>Web2Whitepaper</p>
                <Flex>
                    <p>{user}</p>
                    <Button
                        className='pastone-icon-btn'
                        border='0'
                        margin='auto'
                        width='20px'
                        height='20px'
                        padding='0'
                        backgroundImage={`url("${logoutIcon}")`}
                        onClick={signOut}
                    />
                </Flex>
            </Flex>
            <Grid
                templateColumns='minmax(auto, calc(1 / 3* 100%)) 2fr'
                className='h-fn max-h-fn'
            >
                <ErrorBoundary fallback={FallBackComponent}>
                    <SubmissionForm
                        key={session}
                        onSubmit={onSubmit}
                        isLoading={waitingForHeadings}
                        openResetModal={openResetModal}
                        generating={generating}
                    />
                </ErrorBoundary>
                <ErrorBoundary fallback={({ error, resetError: fallbackReset }) => <FallBackComponent 
                    error={error}
                    resetError={() => {
                        triggerClean();
                        fallbackReset();
                    }}
                />  }>
                    <OutputForm
                        socketMessage={lastMessage}
                        connectionId={connectionId}
                        key={outputSession}
                    />
                </ErrorBoundary>
            </Grid>
        </main>
    );
};

export default MainPage;
