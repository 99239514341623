import { useFieldArray, useFormContext, useForm } from "react-hook-form";
import * as yup from 'yup';
import {
    Button,
    TextField,
    Flex,
} from '@aws-amplify/ui-react';
import { KeyboardEvent, useState } from "react";
import { FieldArrayValues } from "types";

import addIcon from '/assets/add_black_24dp.svg';
import greyAddIcon from '/assets/add_grey_24dp.svg';
import removeIcon from '/assets/clear_black_24dp.svg';

const LinkInputs = ({limitReached}: {limitReached: boolean}) => {
    const [inputText, setInputText] = useState<string>('');
    const { fields, remove, prepend } = useFieldArray<FieldArrayValues, 'links', 'id'>({
        name: "links"
    });
    const { register } = useFormContext();
    const { clearErrors, setError, formState: { errors } } = useForm();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setInputText(event.target.value);
    };

    const validateUrl = yup.string().url('Url non valido.').required('Campo richiesto.');

    const addLink = () => {
        const trimmedInput = inputText.trim();
        const currentLinks = fields.map((linkObj) => linkObj.value);
        try {
            validateUrl.validateSync(trimmedInput);
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                setError('invalidUrl', err);
            } else {
                const error = new Error('Errore di validazione URL');
                setError('invalidUrl', error);
            }
            console.log(errors);
            return err;
        }
        if (trimmedInput && !currentLinks.includes(trimmedInput)) {
            prepend({ type: 'LINK', value: trimmedInput })
            setInputText('');
        }
    };

    const handleDeleteLink = (index: number) => {
        remove(index);
    };

    const handleEnter = (e: KeyboardEvent) => {
        if('Enter' === e.code) {
            e.preventDefault();
            addLink();
        }
    }

    return (
        <Flex
            className='fields-array'
            direction='column'
            gap='16px'
        >
            <Flex
                gap='0'
                alignItems='center'
                className='add-new-field'
            >
                <TextField
                    onKeyDown={handleEnter}
                    className='fieldsarray-textarea'
                    label='new-field'
                    labelHidden
                    value={inputText}
                    onChange={handleChange}
                    textAlign="left"
                    grow='1'
                    hasError={errors.invalidUrl !== null}
                    errorMessage={typeof errors.invalidUrl?.message === 'string' ? errors.invalidUrl.message : undefined}
                >
                </TextField>
                <Button
                    alignSelf='flex-start'
                    disabled={limitReached}
                    onClick={addLink}
                    backgroundImage={limitReached ? `url("${greyAddIcon}")` : `url("${addIcon}")`}
                    className={limitReached ? 'bg-border-grey hover:bg-border-grey' : 'bg-primary-blue'}
                ></Button>
            </Flex>
            {fields.map((field, index) => (
                <Flex
                    key={field.id}
                    gap='0'
                    alignItems='center'
                    className='grow'
                >
                    <TextField
                        className='fieldsarray-textarea'
                        label=''
                        labelHidden
                        grow='1'
                        {...register(`links.${index}.value`)}
                        readOnly={true}
                    >
                    </TextField>
                    <Button
                        border='1px solid #E0E0E0'
                        onClick={() => handleDeleteLink(index)}
                        backgroundColor='#ffffff'
                        backgroundImage={`url("${removeIcon}")`}
                    ></Button>
                </Flex>
            ))}
        </Flex>
    );
}

export default LinkInputs;