import { initConfig } from 'appConfig';
import {
  Authenticator,
  translations,
  ThemeProvider,
  Theme
} from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';
import authComponents from './components/AuthComponents';
import MainPage from './pages/MainPage';
import { AuthProps } from 'types';
import '@aws-amplify/ui-react/styles.css'
import { ErrorBoundary } from '@sentry/react';
import FallBackComponent from 'components/FallbackComponent';
import services from 'components/AuthServices';

I18n.putVocabularies(translations);
I18n.setLanguage('it');
I18n.putVocabularies({
  it: {
    'Enter your Username': 'Inserisci la Mail',
    'Enter your Password': 'Inserisci la Password',
    'User does not exist.': 'L\'utente non esiste.',
    'Incorrect username or password.': 'Password o mail errata.',
    'Reset Password': 'Inserisci la mail della quale vuoi reimpostare la password',
    'Send code': 'Conferma'
  }
});


const customTheme: Theme = {
  name: 'custom-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: '"Titillium Web", sans-serif'},
        static: { value: '"Titillium Web", sans-serif'}
      }
    },
    components: {
      card: {
        padding: '0',
        borderRadius: '0'
      },
      button: {
        borderRadius: '0',
      },
      selectfield: {
        fontSize: '12px',
      },
      input: {
        fontSize: '12px'
      }
    }
  },
  overrides: [
    {
      selector: '#SubmissionForm',
      tokens: {
        components: {
          card: {
            backgroundColor: '#212121'
          },
          button: {
            color: '#008AC9',
            borderColor: '#008AC9'
          },
          input: {
            color: { value: '{colors.neutral.inverse}' }
          },
          textfield: {
            color: { value: '{colors.neutral.inverse}' },
          },
          radiogroup: {
            radio: {
              label: {
                color: '#FFFFFF'
              }
            },
            legend: {
              color: { value: '{colors.neutral.10}' }
            }
          },
          selectfield: {
            label: {
              color: { value: '{colors.neutral.10}' }
            }
          }
        }
      }
    },
    {
      selector: '#OutputForm',
      tokens: {
        components: {
          input: {
            fontSize: '14px',
          },
          textareafield: {
            fontSize: '14px',
          }
        }
      }
    }
  ]
}

const formFields = {
  forgotPassword: {
    username: {
      placeholder: 'Inserisci la Mail',
      label: 'Email'
    }
  },
  signIn: {
    username: {
      placeholder: 'Inserisci la Mail',
      label: 'Email'
    }
  }
}

initConfig();

function App() {
  return (
    <Authenticator.Provider>
      <Authenticator
        components={authComponents}
        formFields={formFields}
        services={services}
        hideSignUp
      >
        {({ signOut }: AuthProps) => {
          return (
            <ErrorBoundary fallback={FallBackComponent}>
              <ThemeProvider theme={customTheme} colorMode='light'>
                <MainPage signOut={signOut} />
              </ThemeProvider>
            </ErrorBoundary>
          )
        }
      }
      </Authenticator>
    </Authenticator.Provider>
  );
}

export default App;
