import { Flex, Button } from "@aws-amplify/ui-react";
import { useCallback } from "react";
import { toast } from "react-toastify";

import blueCopyIcon from '/assets/content_copy_blue_24dp.svg';
import blackCopyIcon from '/assets/content_copy_black_24dp.svg';

const CopyButton = ({ text, callback }: { text: string, callback?: (text?: string) => void; }) => {
    const showToast = () => toast('Testo copiato!');
    const copyToClipboard = useCallback(() => {
        if (callback) {
            showToast();
            return callback(text);
        }
        navigator.clipboard.writeText(text);
        showToast();
    }, [text, callback] );

    const isCopyDisabled = '' === text;

    return (
        <Flex>
            <p
                style={{
                    color: `${text !== '' ? '#008AC9' : '#9E9E9E'}`
                }}
            >
                Copia contenuto
            </p>           
            <Button
                className="h-4 w-4 border-0 pastone-icon-btn"
                padding='0'
                backgroundImage={text !== '' ? `url("${blueCopyIcon}")` : `url("${blackCopyIcon}")`}
                onClick={copyToClipboard}
                isDisabled={isCopyDisabled}
            ></Button>
        </Flex>
    );
};

export default CopyButton