import { Button } from '@aws-amplify/ui-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import draggableIcone from '/assets/drag_indicator_black_24dp.svg';

interface SortableItemProps {
    id: string;
    children: React.ReactNode;
    editable: boolean;
}

function SortableItem(props: SortableItemProps) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({
        id: props.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className='flex'
        >
            {props.editable &&            
                <Button
                    backgroundImage={`url("${draggableIcone}")`}
                    className='inline border-border-grey'
                    {...listeners}
                >
                </Button>
                }
            {props.children}
        </div>
    )
}

export default SortableItem;