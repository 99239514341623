import React from "react";
import { Button, Divider, Grid } from "@aws-amplify/ui-react";


const ResetModal = ({
    modalRef,
    triggerClean
}: {
    modalRef: React.RefObject<HTMLDialogElement>,
    triggerClean: () => void
}) => {
    return (
        <dialog
            ref={modalRef}
            id="reset_modal"
            className="modal"
        >
            <div className="modal-box">
                <h3
                    className="font-bold my-2 text-2xl text-left px-8"
                >
                    Sei sicuro di voler cancellare tutto?
                </h3>
                <Divider
                    width='100%'
                ></Divider>
                <p
                    className="px-8 py-5 text-xs"
                >
                    I contenuti inseriti come fonti e i testi generati verranno cancellati
                </p>
                <Divider
                    width='100%'
                ></Divider>
                <div className="modal-action m-0">
                    <form
                        method="dialog"
                        className="grow"
                    >
                        <Grid
                            paddingBlock='8px'
                            templateColumns='1fr 1fr 1fr'
                            paddingInline='32px'
                        >
                            <button
                                className="btn-sm border-0 font-semibold"
                                style={{
                                    columnCount: '1',
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginInline: 'auto',
                                    background: '#ffffff'
                                }}
                            >
                                Annulla
                            </button>
                            <div
                                style={{
                                    columnCount: '1'
                                }}
                            >
                            </div>
                            <Button
                                className="btn-sm rounded-none h-9 px-2 font-semibold text-sm text-white"
                                onClick={triggerClean}
                                backgroundImage='linear-gradient(to right, #008AC9, #1AA814)'
                            >
                                CANCELLA TUTTO
                            </Button>
                        </Grid>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default ResetModal;