import { Button, Text, useTheme } from '@aws-amplify/ui-react';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { getUrl } from 'aws-amplify/storage';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { config } from 'helpers/FormSchema';

const FileUploader = ({ limitReached, links, files }: {
    limitReached: boolean,
    links: number,
    files: number
}) => {
    const allowedFiles = config.MAX_SOURCES - links - files;
    const { field } = useController({
        name: 'files',
    });
    const { getValues } = useFormContext();
    const { tokens } = useTheme();
    const handleFileRemove = useCallback(
        ({ key }: { key: string }) => {
            getUrl({
                key: key ?? '',
                options: {
                    accessLevel: 'private'
                }
            }).then((resp) => {
                const currentFiles = field.value;
                const bucketKey = decodeURIComponent(resp.url.pathname);
                const updatedFiles = currentFiles.toSpliced(currentFiles.indexOf(bucketKey), 1);
                field.onChange(updatedFiles);
            })
        },
        [field]
    );
    const handleUploadSuccess = ({ key }: { key?: string | undefined }) => {
        getUrl({
            key: key ?? '',
            options: {
                accessLevel: 'private'
            }
        }).then((resp) => {
            const currentFiles = getValues('files');
            const bucketKey = decodeURIComponent(resp.url.pathname);
            field.onChange([...currentFiles, bucketKey]);
        })
    }

    return (
        <StorageManager
            components={{
                DropZone({ children }) {
                    return (
                        <>{children}</>
                    );
                },
                FilePicker({ onClick }) {
                    return (
                        <>
                            <Text
                                color={tokens.colors.neutral[10]}
                                textAlign='left'
                                className='small-text'
                            >
                                File
                            </Text>
                            <Button
                                width='relative.full'
                                onClick={onClick}
                                height='40px'
                                disabled={limitReached}
                            >
                                Carica File
                            </Button>
                        </>
                    )
                },
            }}
            onFileRemove={handleFileRemove}
            acceptedFileTypes={['.pdf', '.docx']}
            accessLevel="private"
            maxFileCount={allowedFiles}
            autoUpload={true}
            isResumable
            onUploadError={(error, { key }) => {
                console.error(error);
                console.error(key);
            }}
            onUploadSuccess={handleUploadSuccess}
        />
    );
};

export default FileUploader;
