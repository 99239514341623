import React from "react";
import { Button, Divider } from "@aws-amplify/ui-react";
import { ErrorProp } from "types";
import { getErrorMessage } from "helpers/errorBuilder";

const ErrorModal = ({
    links,
    modalRef,
    error,
    onClose
}: {
    links: string[],
    modalRef: React.RefObject<HTMLDialogElement>,
    error: ErrorProp|null,
    onClose: () => void
    }) => {
    const { titleText, message } = getErrorMessage(error);
    return (
        <dialog
            ref={modalRef}
            id="error_modal"
            className="modal"
        >
            <div className="modal-box">
                <h3
                    className="font-bold my-2 text-2xl text-left px-8"
                >
                    {titleText}
                </h3>
                <Divider width='100%'></Divider>
                <p
                    className="px-8 py-5 text-xs"
                >
                    {message}
                </p>
                {'extract_resource' === error?.errorCode && (
                    <>
                        <ul className="px-8"
                            style={{
                                listStyle: 'square'
                            }}
                        >
                            {error.resources.map((resource, index) => {
                                const isFile = -1 === links.indexOf(resource);
                                const fileName = isFile ? resource.substring(resource.lastIndexOf('/') + 1) : resource;
                                
                                return (
                                    <li key={index} className="text-xs">
                                        {`(${isFile ? 'file' : 'link'}) `}{fileName}
                                    </li>
                                );
                            })}
                        </ul>
                        <p
                            className="px-8 py-5 text-xs"
                        >
                            Seleziona altre fonti e riprova.
                        </p>
                    </>
                )}
                <Divider width='100%'></Divider>
                <div className="modal-action m-0">
                    <form
                        method="dialog"
                        className="px-8 py-2"
                    >
                        <Button
                            className="btn-sm rounded-none h-9 px-2 font-semibold text-sm text-white"
                            onClick={onClose}
                            backgroundImage='linear-gradient(to right, #008AC9, #1AA814)'
                        >
                            HO CAPITO
                        </Button>
                    </form>
                </div>
            </div>
        </dialog>
    )
}

export default ErrorModal;