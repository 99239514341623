import { Flex, Label } from "@aws-amplify/ui-react";
import Quill from "quill"
import "quill/dist/quill.snow.css";
import { useEffect, useRef, memo } from "react";
import CopyButton from "./CopyToClipboardComponent";

const editorConfig = {
    readOnly: false,
    modules: {
        toolbar: false
    },
    placeholder: 'testo...',
    theme: 'snow',
};

const Editor = memo(({ content, editContent }: {
    content: string, editContent: (value: string) => void
}) => {
    const isMounted = useRef(false);
    const editorDOM = useRef<HTMLDivElement>(null);
    const editor = useRef<Quill | null>(null);
    
    useEffect(() => {
        if (!isMounted.current) {
            editor.current = new Quill(editorDOM.current as HTMLElement, editorConfig);
            console.log(editor.current);
            isMounted.current = true;
        }
    }, [isMounted]);

    if (editor.current && content && content.length > 0) {
        // @ts-expect-error Disabilito typescript perchè è devo accedere a questo metodo protetto
        const delta = editor.current.clipboard.convertHTML(content);
        editor.current.setContents(delta);
    }

    editor.current?.on('text-change', () => {
        editContent(editor.current?.root.innerHTML ?? '');
    });

    const copyHTML = () => {
        if (editor.current && content && content.length > 0) {
            const html = editor.current.getSemanticHTML();
            const blob = new Blob([html], { type: 'text/html' });
            const data = [new ClipboardItem({ 'text/html': blob })]
            navigator.clipboard.write(data);
        }
    }
    
    return (
        <Flex
            className="editorContainer"
            direction="column"
            gap={"xs"}
            maxHeight='1000px'
        >
            <Label className="small-text">
                <Flex
                    justifyContent='space-between'
                >
                    <p>Testo</p>
                    <CopyButton
                        text={content} callback={copyHTML}
                    ></CopyButton>
                </Flex>
            </Label>
            <div ref={editorDOM} className="quill-editor"></div>
        </Flex>
    )
});

export default Editor;