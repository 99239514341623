import { Button, View, useAuthenticator } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { CustomWindow } from "types";
import { ToastContainer, toast } from "react-toastify";
import { signInWithRedirect } from "aws-amplify/auth";

const components = {
  Header() {
    const { route } = useAuthenticator(context => [context.route]);
    const [tempPassSent, setTPS] = useState<boolean>((window as CustomWindow).recoverPasswordNotice ?? false);

    useEffect(() => {      
      if ('signIn' !== route) {
        setTPS(false);
        (window as CustomWindow).recoverPasswordNotice = false;
      }
      if ('confirmResetPassword' === route) {
        const message = document.querySelector('.amplify-heading.amplify-heading--3');
        if (message) {
          message.innerHTML = 'Inserisci il codice che hai ricevuto via email e modifica la tua password'
        }
      }
    }, [route]);

    let headerText;
    switch (route) {
      case 'forgotPassword':
        headerText = 'Reimposta la tua password';
        break;
      case 'confirmResetPassword':
        headerText = 'Reimposta password';
        break;
      case 'forceNewPassword':
        headerText = 'Scegli una nuova password e accedi';
        break;
      default:
        headerText = 'Accedi';
        break;
    }

    return (
      <>
        <p
          className="text-black text-4xl text-center pb-8 font-bold"
        >
          {headerText}
        </p>
        {
          tempPassSent &&
          <p
            className="text-center mb-6"
          >
            Inserisci la password temporanea che ti è stata inviata via mail
          </p>
        }
      </>
    );
  },
  SignIn: {
    Footer() {
      const { toForgotPassword, toSignIn } = useAuthenticator();
      (window as CustomWindow).toSignIn = toSignIn;
      window.addEventListener('beforeunload', () => {
        (window as CustomWindow).recoverPasswordNotice = false;
      });
      return (
        <View textAlign="center"
          className="px-8"
          style={{
            paddingBottom: `var(--amplify-components-authenticator-footer-padding-bottom)`
          }}>
          <Button
            className="amplify-button--primary flex justify-center w-full h-10 mb-8"
            onClick={() => {
              signInWithRedirect({ provider: { custom: 'Digital360' } });
              //window.open(`${D360_LOGIN_BASE_URL}/oauth2/authorize?response_type=code&client_id=${D360_LOGIN_CLIENT_ID}&redirect_uri=${D360_LOGIN_REDIRECT_URI}&state=STATE&scope=aws.cognito.signin.user.admin+openid+email+profile&identity_provider=Digital360`, '_self');
            }}
          >
            Accedi tramite Office365
          </Button>
          <Button
            onClick={toForgotPassword}
            className="font-bold block mx-auto"
            size="small"
            variation="link"

          >
            Password Dimenticata?
          </Button>
        </View>
      );
    }
  },
  Footer() {
    const { route } = useAuthenticator(context => [context.route]);
    const prevRoute = (window as CustomWindow).prevState;
    const showToast = useCallback(() => {
      if (prevRoute && 'signIn' === route && 'confirmResetPassword' === prevRoute) {
        return toast('La tua password è stata modificata correttamente. Puoi effettuare l\'accesso.');
      }
    }, [prevRoute, route]);
    const privacyPolicyURL = new URL('/assets/privacyPolicy.pdf', import.meta.url).href;
    const cookiePolicyURL = new URL('/assets/cookiePolicy.pdf', import.meta.url).href;

    useEffect(() => {
      showToast();
      if (route !== prevRoute) {
        (window as CustomWindow).prevState = route;
      }
    }, [prevRoute, route, showToast]);

    return (
      <>
        <p
          className="text-center text-xs mt-6"
        >
          Per richieste di supporto scrivere a <span
            className="font-semibold"
          >
            <a href="mailto:ai@digital360.it">
              ai@digital360.it
            </a>
          </span>
        </p>
        <p
          className="text-center text-xs mt-6"
        >
          <span className="text-primary-blue">
            <a href={privacyPolicyURL} target="_blank">
              Privacy Policy
            </a> - <a href={cookiePolicyURL} target="_blank">
              Cookie Policy
            </a>
          </span>
        </p>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={true}
          theme="dark"
          closeOnClick
        />
      </>
    )
  }
};

export default components;